import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from 'src/components/Layout/mdx-layout.tsx';
export const _frontmatter = {
  "title": "About",
  "subtitle": "弱いzineって？",
  "description": "それは自分でデザインしたSNSのタイムラインからの避難所であってほしい。",
  "slug": "about",
  "nextlink": "discord",
  "nexttitle": "ディスコードについて"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`「それは自分でデザインしたSNSのタイムラインからの避難所であってほしい。」`}</p>
    <p>{`「それは公園に散歩しに行くときポケットに忍ばせた一冊の本のような存在であってほしい。」`}</p>
    <p>{`弱いzineは以上のコンセプトを元に2021年このウェブサイトを作りました。`}</p>
    <p>{`osamuosanaiがキュレーションとアートワークをAntiSatoriがウェブデザインを担当しました。`}</p>
    <p>{`2022年弱いzineは誰でも参加できるDiscordのサーバーをオープンしました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://discord.gg/ufbx6jsGNc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`弱いzineのDiscordサーバーに参加する`}</a></p>
    <p>{`次は本当に公園のように色んな人が参加できるような場所を作ろうと思ったからです。`}</p>
    <p>{`公園でどんな遊びができるか一緒に模索しませんか？`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      